/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

jQuery(document).ready(function($) {

	var stickyHeaderWidth = 1200,
		stickyHeaderStart = 200,
		headerOffset      = 100, // Height of sticky header
		menuResizeWidth   = 767;

	//--------------------------------------------
	// Menus
	//--------------------------------------------

	$('#header-a #main-menu, #header-b #main-menu, #header-d #main-menu').argonmenu({

		resizeWidth: menuResizeWidth,
		collapserTitle: '',
		animSpeed: 'medium',
		easingEffect: null,
		indentChildren: false,
		childrenIndenter: '&nbsp;',
		expandIcon: '<span class="sub-collapser"><i class="fas fa-angle-down"></i></span>',
		collapseIcon: '<span class="sub-collapser"><i class="fas fa-angle-up"></i></span>'

	});

	$('#main-menu a[href="#"]').click(function(){

		if ($(window).width() <= menuResizeWidth) {

			$(this).parent().find('> .sub-toggle').click();

		}

		return false;

	});

	$('#header-c .nav-icon').click(function(){

		$('body').toggleClass('nav-open');

	});

	//--------------------------------------------
	// Sticky Header
	//--------------------------------------------

	var stickyHeader = $('#header-a, #header-b, #header-c, #header-d');

	function rpjcStickyHeader() {

		var screenSize = $(window).width(),
			scrollTop  = $(document).scrollTop(),
			wrapper    = $('#wrapper');

		// if (screenSize < stickyHeaderWidth ) return;

		if ( scrollTop > stickyHeaderStart ) {

			$(stickyHeader).addClass('stuck');
			$(wrapper).addClass('header-stuck');

		} else {

			$(stickyHeader).removeClass('stuck');
			$(wrapper).removeClass('header-stuck');

		}

	}

	rpjcStickyHeader();

	$(window).scroll(rpjcStickyHeader);

	//--------------------------------------------
	// Site Search
	//--------------------------------------------

	$('.toggle-search').click(function() {

		$('body').toggleClass('search-open');

		$('.site-search input[type="text"]').focus();

		return false;

	});

	$('.site-search-close').click(function() {

		$('body').toggleClass('search-open');

		return false;

	});

	//--------------------------------------------
	// Sliders
	//--------------------------------------------

	// Default Sliders

	var defaultSliders = document.querySelectorAll('.default-slider .splide');

	defaultSliders.forEach(slider => {

		new Splide(slider, {

			type: 'loop',
			perPage: 1,
			pagination: false,
			pauseOnFocus: true

		}).mount();

	});

	// Logo Scrollers

	var logoScrollers = document.querySelectorAll('.logo-scroller .splide');

	logoScrollers.forEach(slider => {

		new Splide(slider, {

			type: 'loop',
			gap: 20,
			autoplay: true,
			interval: 3000,
			perPage: 5,
			perMove: 1,
			arrows: false,
			pagination: false,
			breakpoints: {
				960: {
					perPage: 4
				},
				768: {
					perPage: 3
				},
				480: {
					perPage: 2
				}
			}

		}).mount();

	});

	// Testimonial Scrollers

	var testimonialScrollers = document.querySelectorAll('.section-testimonials .splide');

	testimonialScrollers.forEach(slider => {

		new Splide(slider, {

			type: 'loop',
			perPage: 2,
			perMove: 1,
			pauseOnFocus: true,
			pagination: false,
			arrows: true,
			autoplay: true,
			interval: 5000,
			speed: 400,
			gap: 20,
			breakpoints: {
				600: {
					perPage: 1
				}
			}

		}).mount();

	});

	// Section Sliders

	var sectionSliders = document.querySelectorAll('.section-slider .splide');

	sectionSliders.forEach(slider => {

		new Splide(slider, {

			type: 'fade',
			perPage: 1,
			arrows: false,
			pagination: true,
			autoplay: true,
			interval: 5000,
			speed: 2000,
			pauseOnFocus: true

		}).mount();

	});

	// Landing Slider

	var landingSlider = document.querySelector('#landing .splide');

	if (landingSlider) {

		const landingSliderSplide = new Splide(landingSlider, {

			type: 'fade',
			rewind: true,
			perPage: 1,
			autoplay: true,
			interval: 7000,
			speed: 2000,
			arrows: false,
			pagination: false,
			drag: false,
			pauseOnHover: false

		});

		landingSliderSplide.on( 'pagination:updated', function() {

			if ( landingSliderSplide.length === 1 ) {

				$('#landing .splide__pagination').hide();

			} else {

				$('#landing .splide__pagination').show();

			}

		} );

		landingSliderSplide.mount();

	}

	// Video Sliders

	var videoSlider = document.querySelectorAll('.video-slider .splide');

	videoSlider.forEach(slider => {

		new Splide(slider, {

			type: 'loop',
			perPage: 3,
			perMove: 1,
			pauseOnFocus: true,
			pagination: false,
			arrows: true,
			gap: 20,
			breakpoints: {
				960: {
					perPage: 2
				},
				600: {
					perPage: 1
				}
			}

		}).mount();

	});

	// Home Testimonials Slider

	var homeTestimonialSlider = document.querySelectorAll('.testimonial-slider .splide');

	homeTestimonialSlider.forEach(slider => {

		new Splide(slider, {

			type: 'fade',
			perPage: 1,
			arrows: false,
			pagination: false,
			arrows: true,
			autoplay: true,
			interval: 5000,
			speed: 2000,
			rewind: true,
			pauseOnFocus: true

		}).mount();

	});

	// Home Video Slider

	var homeTestimonialSlider = document.querySelectorAll('.home-video-slider .splide');

	homeTestimonialSlider.forEach(slider => {

		new Splide(slider, {

			type: 'fade',
			perPage: 1,
			arrows: false,
			pagination: false,
			arrows: true,
			autoplay: true,
			interval: 5000,
			speed: 2000,
			rewind: true,
			pauseOnFocus: true

		}).mount();

	});

	// Related Designs Scroller

	var designsScrollers = document.querySelectorAll('.related-designs-slider .splide');

	designsScrollers.forEach(slider => {

		new Splide(slider, {

			type: 'loop',
			perPage: 3,
			perMove: 1,
			pauseOnFocus: true,
			pagination: false,
			arrows: true,
			autoplay: true,
			interval: 5000,
			speed: 400,
			gap: 10,
			breakpoints: {
				960: {
					perPage: 2
				},
				650: {
					perPage: 1
				}
			}

		}).mount();

	});

	//--------------------------------------------
	// Lightbox
	//--------------------------------------------

	$('.lightbox-image').magnificPopup({
		closeBtnInside: false,
		mainClass: 'rpjc-magnific',
		closeMarkup: '<div title="%title%" class="mfp-close"><span></span><span></span></div>',
		type: 'image'
	});

	$('.lightbox-image.wp-block-image').magnificPopup({
		delegate: 'a',
		closeBtnInside: false,
		mainClass: 'rpjc-magnific',
		closeMarkup: '<div title="%title%" class="mfp-close"><span></span><span></span></div>',
		type: 'image'
	});

	$('.lightbox-iframe').magnificPopup({
		closeBtnInside: false,
		mainClass: 'rpjc-magnific',
		closeMarkup: '<div title="%title%" class="mfp-close"><span></span><span></span></div>',
		type: 'iframe'
	});

	$('.lightbox-team').magnificPopup({
		closeBtnInside: false,
		mainClass: 'rpjc-magnific rpjc-magnific-team',
		closeMarkup: '<div title="%title%" class="mfp-close"><span></span><span></span></div>',
		type: 'ajax',
		fixedContentPos: true,
		callbacks: {
			ajaxContentAdded: function() {
				$('.close-team-lightbox').click(function(e){
					e.preventDefault();
					$.magnificPopup.close();
				});
			}
		}
	});

	$('.gallery-item').magnificPopup({
		delegate: 'a',
		closeBtnInside: false,
		mainClass: 'rpjc-magnific',
		closeMarkup: '<div title="%title%" class="mfp-close"><span></span><span></span></div>',
		type: 'image',
		closeOnBgClick: false,
		gallery: {
			enabled: true,
			arrowMarkup: '<div title="%title%" class="mfp-arrow mfp-arrow-%dir%"><span></span><span></span></div>'
		}
	});

	//--------------------------------------------
	// Accordion
	//--------------------------------------------

	$('.accordion .accordion-content').hide();

	var hash = window.location.hash;

	// Check if there's hash tag in the URL
	if (hash.length) {

		var selectedAccordionItem = $('body').find('.accordion .accordion-item' + hash);

		// If yes, open the matching accordion item
		if (selectedAccordionItem.length) {

			var scrollPx = $(window).width() >= stickyHeaderWidth ? selectedAccordionItem.offset().top - headerOffset : selectedAccordionItem.offset().top;

			selectedAccordionItem.addClass('selected').find('.accordion-content').show();

			// Perform scroll down
			$('html, body').animate({scrollTop: scrollPx},'slow');

		}

	}

	$('.accordion-handle').click(function(){

		var accordion = $(this).parent().parent();

		if ($('.accordion-content').not(':animated')) {

			if ($(this).parent().hasClass('selected')) {

				$(this).next().slideUp(300).end().parent().removeClass('selected');

			} else {

				$(accordion).find('.accordion-item').removeClass('selected');
				$(this).parent().addClass('selected');
				$(accordion).find('.accordion-content').slideUp(300);

				var activeAccordion = $(this).parent();

				$(this).next().slideDown(300, function(){

					var scrollPx = $(window).width() >= stickyHeaderWidth ? activeAccordion.offset().top - headerOffset : activeAccordion.offset().top;

					// Scroll to selected accordion item in viewport
					$('html, body').animate({scrollTop: scrollPx},'slow');

				});

			}

		}

		return false;

	});

	//--------------------------------------------
	// Tabs
	//--------------------------------------------

	// Check if there's hash tag in the URL
	if (hash.length) {

		var selectedTabPanel = $('body').find('.tabbed-content .tab-window ' + hash);

		// If yes, open the matching tab item
		if (selectedTabPanel.length) {

			var scrollPx = $(window).width() >= stickyHeaderWidth ? selectedTabPanel.offset().top - headerOffset : selectedTabPanel.offset().top;

			$('.tabbed-content .tab-window .tab-panel').hide();
			$('.tabbed-content .tab-nav li a[href="' + hash + '"]').parent().addClass('active');

			selectedTabPanel.show();

			// Perform scroll down
			$('html, body').animate({scrollTop: scrollPx},'slow');

		}

	} else {

		$('.tabbed-content .tab-nav li:first-child').addClass('active');
		$('.tabbed-content .tab-window .tab-panel').not(':first-child').hide();

	}

	$('.tabbed-content .tab-nav a').click(function(){

		var target = $(this).attr('href');

		$(this).parent().addClass('active').siblings().removeClass('active');

		$(target).show().siblings().hide();

		return false;

	});

	//--------------------------------------------
	// Scrolling
	//--------------------------------------------

	// Scroll to element

	$('.scroll-to').click(function(){

		var selected = $(this).attr('href'),
			scrollPx = ($(window).width() < stickyHeaderWidth) ? $(selected).offset().top - 20 : $(selected).offset().top - headerOffset;

		$('html, body').animate({scrollTop: scrollPx},'slow');

		return false;

	});

	// Scroll to top link

	$('a[href="#top"]').click(function(){

		$('html, body').animate({scrollTop:0}, 'slow');

		return false;

	});

	$('.scrolldown').click(function(){

		var target = $(this).parent().next(),
			distance;

		if ($(window).width() < stickyHeaderWidth) {

			distance = $(target).offset().top;

		} else {

			distance = $(target).offset().top - headerOffset;

		}

		$('html, body').animate({scrollTop: distance},'slow');

		return false;

	});

    //--------------------------------------------
	// Responsive Horizontal Scrolling
	//--------------------------------------------

	$( '.responsive-scroll' ).each( function() {

		var element       = $( this ),
			scrollWrapper = $( '<div />', {

				'class' : 'scrollable',
				'html'  : '<div class="scrollable-inner" />'

			} ).insertBefore( element );

		element.data( 'scrollWrapper', scrollWrapper );
		element.appendTo( scrollWrapper.find( 'div' ) );

		if ( element.outerWidth() > element.parent().outerWidth() ) {

			element.data( 'scrollWrapper' ).addClass( 'has-scroll' );

		}

		$( window ).on( 'resize orientationchange', function() {

			if ( element.outerWidth() > element.parent().outerWidth() ) {

				element.data( 'scrollWrapper' ).addClass( 'has-scroll' );

			} else {

				element.data( 'scrollWrapper' ).removeClass( 'has-scroll' );

			}

		} );

	} );

	//--------------------------------------------
	// fitVids
	//--------------------------------------------

	$('.main').fitVids();

	//--------------------------------------------
	// Read More
	//--------------------------------------------

	$('.read-more-content').hide();

	$('.read-more-button .btn').on('click', function(e){

		e.preventDefault();

		var readMoreButton = $(this),
			moreText       = readMoreButton.attr('data-more'),
			lessText       = readMoreButton.attr('data-less');

		$(readMoreButton).parent().next().not(':animated').slideToggle(300, function(){

			if ($(this).is(':visible')) {

				readMoreButton.html(lessText);

			} else {

				readMoreButton.html(moreText);

			}

		});

	});

	//--------------------------------------------
	// Team B
	//--------------------------------------------

	$( '.team-b .team-link' ).click( function( e ) {

		e.preventDefault();

		var details = $( this ).attr( 'href' ),
			link    = $( this );

		if ( $( link ).hasClass( 'active' ) ) {

			$( details ).hide( 0, function() {

				$( link ).removeClass( 'active' );

			});

		} else {

			$( '.person-details' ).not( details ).hide( 0, function() {

				$( '.team-b .team-link' ).not( link ).removeClass( 'active' );

			} );

			$( link ).addClass( 'active' );

			$( details ).show( 0 );

		}

	} );

	$( '.person-details .close' ).click( function() {

		var parentID = $( this ).parent().attr( 'id' );

		$( this ).parent().hide( 0, function() {

			$( 'a[href="#' + parentID + '"]' ).removeClass( 'active' );

		} );

	} );

	function rpjc_people_layout() {

		if ( $( window ).width() >= 768 ) {

			$( '.person-details' ).each( function() {

				$( this ).appendTo( $( this ).parent( '.row' ) );

			} );

		} else {

			$( '.person-details' ).each( function() {

				var thisID = $( this ).attr( 'id' );

				$( this ).insertAfter( $('a[href="#' + thisID + '"]').parent() );

			} );

		}

	}

	$( window ).on( 'load resize orientationchange', rpjc_people_layout );


	//--------------------------------------------
	// Transient Displays
	//--------------------------------------------

	$('.transient-displays .show-gallery').click(function(event){

		event.preventDefault();

		if (!$(this).siblings('.gallery').length) return;

		$(this).siblings('.gallery').magnificPopup({
			delegate: 'a',
			closeBtnInside: false,
			mainClass: 'rpjc-magnific',
			closeMarkup: '<div title="%title%" class="mfp-close"><span></span><span></span></div>',
			type: 'image',
			closeOnBgClick: false,
			gallery: {
				enabled: true,
				arrowMarkup: '<div title="%title%" class="mfp-arrow mfp-arrow-%dir%"><span></span><span></span></div>'
			}
		}).magnificPopup('open');

	});

	//--------------------------------------------
	// Galleries
	//--------------------------------------------

	if ($('.galleries-page').length) {

		// load the first tab
		var firstTab = $('.sub-nav li:first-child a');
		$('#main .container').html('<i class="fas fa-sync fa-spin"></i>');
		loadGallery(firstTab.attr('data-id'), firstTab.attr('data-pos'));

		$('.sub-nav a').click(function(event){

			event.preventDefault();

			// clear container and load more link if exists
			$('#main .container').html('<i class="fas fa-sync fa-spin"></i>');
			$('#main .load-more-block').remove();

			// get the gallery page id, and the galleries array index in the repeater
			loadGallery($(this).attr('data-id'), $(this).attr('data-pos'));

		});

	}


	//--------------------------------------------
	// Floorplan Viewer
	//--------------------------------------------

	// photoswipe for floorplan zooming
	var pswpElement = document.querySelectorAll('.pswp')[0];
	var pswpImages  = $('a.pswp-image');
	var pswpOptions = {
		index: 0,
		history: false,
		closeOnScroll: false
	};

	$(pswpImages).each(function(){

		$(this).click(function(e){

			e.preventDefault();

			var imgZoom = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, [

				{
					src: $('img', this).attr('src'),
					w: $('img', this).attr('width'),
					h: $('img', this).attr('height')
				}

			], pswpOptions);

			imgZoom.init();

		});

	});

	var activeFloorPlan = '.floorplan1';

	// flip floorplan
	$('.floorplan-viewer .tools .flip').click(function(){
		if (activeFloorPlan === '.floorplan1') {
			activeFloorPlan = '.floorplan2';
		} else {
			activeFloorPlan = '.floorplan1';
		}
		$('.floorplan1').toggle();
		$('.floorplan2').toggle();
	});

	$('.floorplan-viewer .tools .zoom').click(function(e){

		e.preventDefault();

		var imgZoom = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, [

			{
				src: $(activeFloorPlan).find('img').attr('src'),
				w: $(activeFloorPlan).find('img').attr('width'),
				h: $(activeFloorPlan).find('img').attr('height')
			}

		], pswpOptions);

		imgZoom.init();
	});

});

function loadGallery(galleryPageId, galleryArrayIndex, paginationFrom, paginationTo) {

	var galleryTemplate = Handlebars.compile('<div class="gallery-item"><div class="thumb"><img src="{{thumbnail}}" alt="{{alt}}"></div><div class="overlay"><i class="fas fa-plus" data-type="gallery" data-id="{{id}}"></i><a href="{{url}}" title="{{title}} - {{caption}}">{{title}}</a></div></div>');

	$.ajax({
		type : "get",
		dataType : "json",
		url : myAjax.url,
		data: {
			action: 'rpjc_show_gallery_images',
			nonce: myAjax.nonce,
			id: galleryPageId,
			pos: galleryArrayIndex,
			from: paginationFrom,
			to: paginationTo
		},
		success: function(res) {

			if (Object.keys(res).length === 0) return;

			if (!('images' in res)) return;

			if (res.images.length === 0) return;

			var html = '';

			res.images.forEach(image => html += galleryTemplate({ title: image.title, url: image.url, alt: image.alt, caption: image.caption, thumbnail: image.thumbnail }));

			if ($('#main .container').find('.lightbox-gallery').length === 0) {
				// add html div wrapper
				html = '<div class="lightbox-gallery gallery-a cols-4">' + html + '</div>';
				$('#main .container').html(html);
			} else {
				$('#main .container .lightbox-gallery').append(html);
			}

			// check if we need to include 'Load more' link
			if ('pagination' in res && Object.keys(res.pagination).length > 0) {
				if (res.pagination.from < res.pagination.to) {
					if ($('#main .container').find('.load-more').length === 0) {
						$('#main .container').append('<a href="#" class="load-more" data-from="' + res.pagination.from + '" data-to="' + res.pagination.to + '" data-id="' + galleryPageId + '" data-pos="' + galleryArrayIndex + '">Load More</a>');
						$('#main .load-more').click(function(event){
							event.preventDefault();
							loadGallery($(this).attr('data-id'), $(this).attr('data-pos'), $(this).attr('data-from'), $(this).attr('data-to'));
						});
					}
					else {
						var loadMoreLink = $('#main .container').find('.load-more');
						loadMoreLink.attr('data-from',res.pagination.from);
						loadMoreLink.attr('data-to',res.pagination.to);
					}
				}
			} else {
				// remove Load More link as there are none to load
				$('#main .load-more').remove();
			}

			$('#main .gallery-item').magnificPopup({
				delegate: 'a',
				closeBtnInside: false,
				mainClass: 'rpjc-magnific',
				closeMarkup: '<div title="%title%" class="mfp-close"><span></span><span></span></div>',
				type: 'image',
				closeOnBgClick: false,
				gallery: {
					enabled: true,
					arrowMarkup: '<div title="%title%" class="mfp-arrow mfp-arrow-%dir%"><span></span><span></span></div>'
				}
			});
		}
	});

}

function elementExist(id) {

	var element = document.getElementsByClassName(id);
	return typeof(element) != 'undefined' && element != null;

}

const screenWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

//--------------------------------------------
// ScrollTrigger - page scroll animation
// https://greensock.com/docs/v3/Plugins/ScrollTrigger
//--------------------------------------------

if ( elementExist('animated-text') && screenWidth >= 1200 ) {
	gsap.registerPlugin(ScrollTrigger);
	gsap.to('.animated-text', {
		x: -65,
		opacity: 1,
		duration: 1,
		backgroundPosition: '1300px 0',
		scrollTrigger: {
			trigger: ".animated-text",
			start: 'top center',
			end: 'bottom center',
			// markers: true,
			// once: true,
			scrub: true
		}
	});
}
